.container {
  background-color: #555;
  width: 100%;
  height: 100%;
  padding: 30px;
}
.container-cart {
  height: 100%;
  max-width: 400px;
  padding: 30px;
}
.notification {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--brown);
  border-radius: 50%;
  border: 1px solid var(--brown);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_container {
  width: 300px;
  border-bottom: 1px solid var(--placeholder-text);
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}
.search_input {
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;
  outline: none;
  font-size: 24px;
}
.search_input::placeholder {
  color: var(--placeholder-text);
}

.body_container {
  display: flex;
  /* flex-wrap: wrap; */
}
.categories_section {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}
.categories_section_mobile {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px;
}
.category {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.category_title {
  color: var(--errie-black);
  font-size: 24px;
  font-weight: 600;
}
.checkbox_container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
}
.cards_section {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.clear {
  color: var(--error);
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}
.countdown-container {
  color: var(--brown);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  padding: 10px;
}
.countdown-title {
  color: var(--black);
  font-size: 40px;
}
@media (max-width: 600px) {
  .categories_section {
    display: none;
  }
  .options {
    display: flex;
  }
  .countdown-container {
    padding: 5px;
  }
  .countdown-title {
    font-size: 17px;
    margin-bottom: 3px;
  }
  .container-cart {
    max-width: 250px;
    padding: 15px;
  }
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
  .categories_section {
    display: none;
  }
  .countdown-container {
    padding: 8px;
  }
  .countdown-title {
    font-size: 17px;
    margin-bottom: 3px;
  }
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
