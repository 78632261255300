/* ExpandableText.module.css */
.container {
  display: flex;
  flex-direction: column;
}

.text {
  overflow: hidden;
}

.lineClamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.button {
  color: #a52a2a;
  text-decoration: underline;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: fit-content;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
