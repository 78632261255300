.container {
  /* width: 295px; */
  /* aspect-ratio: 0.62; */
  max-width: 300px;
  width: 100%;
  cursor: pointer;
}
.card-top {
  /* height: 380px; */
  width: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.card-bottom {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  gap: 5px;
}
.heart-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.heart-container img {
  background-color: none;
  border: none;
}
.tags-container {
  position: absolute;
  top: 15px;
  left: 15px;
  gap: 8px;
  max-width: 70%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.hot-container {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: var(--white);

  padding: 5px;
}
.sold-container {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  color: var(--white);
  /* position: absolute;
  top: 15px;
  left: 90px; */
  padding: 5px;
}

/* Media query for screens smaller than 600px (e.g., smartphones) */
@media (max-width: 600px) {
  .container {
    /* width: 100%; */

    cursor: pointer;
  }
  .card-top {
    border-radius: 8px;
    position: relative;
  }
  .card-bottom {
    font-size: 13px;
  }
  .heart-container {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }

  .hot-container {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: var(--white);
    /* position: absolute;
    top: 130px;
    left: 5px; */
    padding: 5px;
    font-size: 11px;
    font-weight: bold;
  }
  .hot-container img {
    width: 10px;
  }
  .sold-container {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    color: var(--white);
    /* position: absolute;
    top: 160px;
    left: 5px; */
    padding: 5px;
    font-size: 11px;
    font-weight: bold;
  }
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
/* @media (min-width: 601px) and (max-width: 900px) {
} 

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
/* @media (min-width: 901px) and (max-width: 1200px) {
} */

/* Media query for screens larger than 1200px (e.g., large desktops) */
/* @media (min-width: 1201px) {
} */
