.modalContainer {
  max-width: 80vw;
  width: 100%;
  height: 100%;
  max-height: 80vh;
  border-radius: 0.75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f8f9fa; /* Tailwind bg-pale-grey */
}

.closeButton {
  position: absolute;
  top: -2rem; /* -top-8 */
  right: 0.5rem; /* right-2 */
  color: white;
  font-size: 1.25rem; /* text-xl */
}

.iframe {
  width: 100%;
  height: 100%;
}
