.container {
  background-color: var(--white);
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--black);
  font-weight: 600;
  flex-wrap: wrap;
}
.img {
  width: 120px;
  height: 150px;
  border-radius: 8px;
}
.number {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--dreamy-cloud);
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
}
.total {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 16px;
}
.row {
  display: flex;
  gap: 10px;
  text-align: center;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  line-height: 1.5;
}
.color {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border:0.5px solid   rgba(0, 0, 0, 0.5);
}
.category {
  font-size: 14px;
  font-weight: 500;
  color: var(--grey-text);
}
.title {
  font-size: 16px;
  font-weight: 600;
}
.subtitle {
  font-size: 14px;
  font-weight: 500;
  flex-wrap: wrap;
}
.forDesktop{
  display: flex !important;
}
.forMobile{
  display: none !important;
}
/* Media query for screens smaller than 600px (e.g., smartphones) */
@media (max-width: 600px) {
  .form_container {
    width: 250px;
  }
  .card_container {
    width: 250px;
  }
  .semi_item {
    width: 100%;
  }
  .forDesktop{
    display: none !important;
  }.forMobile{
    display: flex !important;
  }

}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
  .semi_item {
    width: 100%;
  }
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
.photoIcon {
  width: 116px;
  position: relative;
  height: 150px;
  object-fit: cover;
  }
  .springCollection {
  position: relative;
  line-height: 150%;
  font-weight: 500;
  }
  .mensUaStorm {
  width: 207px;
  position: relative;
  line-height: 150%;
  font-weight: 600;
  color: #1b1b1b;
  display: inline-block;
  }
  .productName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  }
  .lWrapper {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 8px;
  }
  .frameChild {
  width: 0px;
  position: relative;
  height: 16px;
  object-fit: contain;
  }
  .colorChild {
  width: 20px;
  position: relative;
  border-radius: 4px;
  background-color: #9195a4;
  height: 20px;
  }
  .color {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
  gap: 10px;
  text-align: left;
  }
  .frameGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  }
  .noteIcon {
  width: 20px;
  position: relative;
  height: 20px;
  }
  .frameParent {
  width: 207px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 14px;
  color: #1b1b1b;
  }
  .productContent {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  }
  .product2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  }
  .div {
  width: 100px;
  position: relative;
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  }
  .div1 {
  position: relative;
  line-height: 150%;
  font-weight: 600;
  }
  .quantity {
  width: 90px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 13px 8px;
  text-align: center;
  }
  .div2 {
  width: 100px;
  position: relative;
  line-height: 150%;
  font-weight: 600;
  text-align: right;
  display: inline-block;
  flex-shrink: 0;
  }
  .parent {
  width: 335px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #1b1b1b;
  }
  .content {
  display: flex;
  flex-direction: column;
margin:0 auto;
  gap: 16px;
  }
  .dividerIcon {
  width: 334px;
  position: relative;
  max-height: 100%;
  object-fit: contain;
  }
  .product {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
  font-size: 12px;
  color: rgba(27, 27, 27, 0.7);
  font-family: 'Plus Jakarta Sans';
  }
  @media (max-width: 365px) {
    .parent {
      width: 250px;}
      .frameParent {
        width: 150px;}
  }
  