.container {
  max-width: 600px;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  padding: 48px 32px;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 48px;
}

.clearButtonText {
  font-size: 18px;
  font-weight: bold;
}

.clearButton {
  color: #ad6b46;
  font-weight: 600;
  font-size: 15px;
  text-decoration: underline;
}

.filterSection {
  margin-top: 20px;
}

.headingSection {
  font-size: 20px;
  font-weight: 600;
}

.sortHeading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.filterButtons {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.filterButton {
  padding: 10px 16px;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  font-weight: 400;
  color: #323232;
}

.activeButton {
  background-color: #ad6b46;
  color: white;
  font-weight: 600;
}

.inactiveButton {
  background-color: transparent;
}

.accordionContainer {
  padding-bottom: 12px;
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 12px;
}

.inactiveFilter {
  background-color: transparent;
}

.sliderContainer {
  padding: 16px;
}

.buttonDone {
  background-color: #ad6b46;
  color: white;
  font-weight: 600;
  padding: 13px 20px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}
