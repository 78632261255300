.sidebar {
  height: 100%;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

h1 {
  font-size: 15px;
  color: var(--errie-black);
}
p {
  font-size: 13px;
  color: var(--rhine-castle);
}
.line {
  border-top: 2px solid var(--pale-grey);
}
.button {
  border-radius: 10px;
  padding: 13px;
  border: 1px solid var(--brown);
  color: var(--brown);
  background-color: var(--white);
  font-weight: bold;
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
}
.link:hover {
  font-weight: 500;
}
.link {
  text-decoration: none;
  color: var(--black);
}
.dropdown-section {
width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}