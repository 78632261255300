.container {
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  position: relative;
  /* gap: 100px; */
}

.images-section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.large-image {
  aspect-ratio: 0.8 !important;
  width: calc(100% - 130px);
  /* height: 650px; */
  border-radius: 9px;
}

.small-images-container {
  /* /flex: 1; */
  display: flex;

  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

.small-image {
  width: 100px;
  aspect-ratio: 0.8;

  opacity: 0.8;
  border-radius: 8px;
  cursor: pointer;
}

.order-section {
  display: flex;
  flex-wrap: wrap;
  /* flex: 1; */
  flex-direction: column;
  max-width: 500px;
  gap: 10px;
  background-color: #fafafa;
  border-radius: 14px;
  padding: 24px;
}

.line {
  border-top: 1px solid var(--unicorn-silver);
}

.delivery-icon {
  width: 57.81px;
  height: 56px;
}

.heart-icon {
  /* height: calc(100% - 26px); */
  padding: 10px;
  border: 1px solid var(--unicorn-silver);
  border-radius: 10px;
  cursor: pointer;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: center; */
  align-items: stretch;
  font-size: 18px;
  font-weight: 600;
  gap: 20px;
}

.select,
.buttonVariant {
  background-color: #fff;
  box-shadow: 0 5px 20px rgba(255, 255, 255, 0.1);
}

.select:focus *,
.select .Mui-focused {
  border-color: var(--brown) !important;
  color: var(--brown) !important;
}

.cart-button {
  background-color: var(--brown);
  color: var(--white);
  flex-wrap: wrap;
  display: flex;
  width: 294px;
  padding: 13px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1;
  flex-shrink: 0;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.cart-button:hover {
  opacity: 0.8;
}

.cart-button:disabled {
  background-color: #9f9f9f99;
  cursor: default;
}

.heart-button {
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
}

@media (max-width: 600px) {
  .images-section {
    flex: auto;
  }

  .order-section {
    flex: auto;
  }

  .container {
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }

  .large-image {
    width: 100%;
    /* height: 400px; */
  }
  .small-image {
    height: 130px;
    aspect-ratio: 0.8;
  }
  .small-images-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }

  .images-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .btns-action {
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 20;
    padding: 0.75rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
  }

  .btn-count {
    min-width: 50px;
    width: 20%;
  }
}

.btn-count {
  min-width: 90px;
}

/* Media query for screens between 601px and 900px (e.g., tablets) */
@media (min-width: 601px) and (max-width: 900px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .btn-count {
    min-width: 75px;
  }
  .btns-action {
    padding: 0.7rem 0.9rem;
  }
  .row {
    padding: 0;
    gap: 12px;
  }
  .images-section {
    flex: auto;
  }

  .order-section {
    /* flex: auto; */
    padding: 16px;
  }

  .large-image {
    width: 100%;
    /* height: 400px; */
  }

  .small-images-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
  .small-image {
    height: 130px;
    aspect-ratio: 0.8;
  }
  .images-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

/* Media query for screens between 901px and 1200px (e.g., larger tablets and small desktops) */
@media (min-width: 901px) and (max-width: 1200px) {
  .images-section {
    flex: 3;
  }

  .order-section {
    flex: 2;
  }
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media (min-width: 1201px) {
}
@media (max-width: 350px) {
  .btn-count {
    min-width: 60px;
  }
  .btns-action {
    padding: 0.5rem 0.6rem;
  }
  .row {
    gap: 10px;
  }
}

.sizeGuide {
  display: flex;
  align-items: center;
  gap: 10px;

  & button {
    color: #ad6b46;
    display: block;
    text-decoration: underline;
  }
}
